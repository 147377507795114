import React from "react";
import { Report } from "../components/Report";
import { SEO } from "../components/SEO";
import type { HeadProps } from "gatsby";

const ReportPage = () => <Report />;

export default ReportPage;

export function Head({ location }: HeadProps) {
  return <SEO title="Transparency Report" pathname={location.pathname} />;
}
