import React from "react";
import axios from "axios";
import { BigNumber, BigNumberish, utils } from "ethers";
import { useQuery } from "react-query";
import { useFetchStakingPoolStatsLatestQuery } from "../graphql/thegraph/generated";
import {
  CTSI_POS_THEGRAPH_ENDPOINT,
  CTSI_POS_NODE_ADDRESS,
  CTSI_POS_POOL_ADDRESS,
  CTSI_POS_POOL_OWNER,
  INFRA_PER_MONTH,
} from "../config";

const etherscanUrl = `https://api.etherscan.io/api?module=account&action=txlist&address=${CTSI_POS_NODE_ADDRESS}`;
const coingeckUrl =
  "https://api.coingecko.com/api/v3/simple/price?ids=ethereum,cartesi&vs_currencies=usd";

function monthDiff(d1: Date, d2: Date): number {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const Report = () => {
  const spentInfraUsd =
    (monthDiff(new Date(2021, 10, 1), new Date()) + 1) * INFRA_PER_MONTH;

  // ETHERSCAN SPENT WEI
  const spentWeiQuery = useQuery("spentWei", () =>
    axios
      .get<{
        result: [
          {
            from: string;
            gasUsed: string;
            gasPrice: string;
            error: string;
          }
        ];
      }>(etherscanUrl)
      .then(({ data }) => {
        let spentWei: BigNumberish = 0;
        data.result.forEach((transaction) => {
          if (transaction.error === "1") return;
          if (transaction.from !== CTSI_POS_NODE_ADDRESS) return;
          spentWei = BigNumber.from(transaction.gasUsed)
            .mul(transaction.gasPrice)
            .add(spentWei);
        });
        return spentWei;
      })
  );

  // COINGECKO ETH PRICE
  const pricesQuery = useQuery("ethPrice", () =>
    axios.get<{
      ethereum: { usd: number };
      cartesi: { usd: number };
    }>(coingeckUrl)
  );

  // THEGRAPH GAINED CTSI
  const theGraphQuery = useFetchStakingPoolStatsLatestQuery(
    { endpoint: CTSI_POS_THEGRAPH_ENDPOINT },
    { pool: CTSI_POS_POOL_ADDRESS }
  );

  const queries = [spentWeiQuery, pricesQuery, theGraphQuery];
  const isLoading = queries.some(
    (query) => query.isLoading || query.isFetching
  );
  const isError = queries.some((query) => query.isError);

  const { data: spentWei } = spentWeiQuery;
  const { data: prices } = pricesQuery;
  const { data: theGraphResult } = theGraphQuery;

  const priceEth = prices?.data.ethereum.usd || 0;
  const priceCTSI = prices?.data.cartesi.usd || 0;
  const spentGasEth = spentWei ? Number(utils.formatEther(spentWei)) : 0;
  const spentGasUsd = spentGasEth * priceEth;
  const spentUsd = spentGasUsd + spentInfraUsd;
  const gainedCtsi = theGraphResult?.stakingPool?.totalCommission
    ? Number(
        utils.formatEther(
          BigNumber.from(theGraphResult?.stakingPool?.totalCommission)
        )
      )
    : 0;
  const gainedUsd = gainedCtsi * priceCTSI;
  const diffUsd = gainedUsd - spentUsd;

  return (
    <div>
      <h2>Transparency Report.</h2>
      <h3>Addresses</h3>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>Owner</td>
            <td>
              {" "}
              <a
                href={`https://etherscan.io/address/${CTSI_POS_POOL_OWNER}`}
                target="_blank"
                rel="noreferrer"
                className="address"
              >
                <small>{CTSI_POS_POOL_OWNER}</small>
              </a>
            </td>
          </tr>
          <tr>
            <td>Pool</td>
            <td>
              {" "}
              <a
                href={`https://etherscan.io/address/${CTSI_POS_POOL_ADDRESS}`}
                target="_blank"
                rel="noreferrer"
                className="address"
              >
                <small>{CTSI_POS_POOL_ADDRESS}</small>
              </a>
            </td>
          </tr>
          <tr>
            <td>Node</td>
            <td>
              {" "}
              <a
                href={`https://etherscan.io/address/${CTSI_POS_NODE_ADDRESS}`}
                target="_blank"
                rel="noreferrer"
                className="address"
              >
                <small>{CTSI_POS_NODE_ADDRESS}</small>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Accounting</h3>
      <p>
        Every time the node produces a block it spends gas. Every time an user
        stakes or unstakes funds, the node needs to rebalance the pool and also
        spends gas. In addition, running a noether node coupled to an ethereum
        node is a costly operation.
      </p>
      <p>
        Those are the reason why a fee exists in the protocol. Some pools will
        try to make a profit out of this process. Durable aims to stay break
        even.
      </p>
      {(!isLoading && (
        <ul>
          <li>
            spent gas ={" "}
            <b>
              <small>ETH</small>&nbsp;{Number(spentGasEth).toFixed(3)}
            </b>
            , equivalent to <b>${Number(spentGasUsd).toFixed(0)}</b>
          </li>
          <li>
            commission ={" "}
            <b>
              <small>CTSI</small>&nbsp;{Number(gainedCtsi).toFixed(0)}
            </b>
            , equivalent to <b>${Number(gainedUsd).toFixed(0)}</b>
          </li>
          <li>
            infra cost ~1000$/month ={" "}
            <b>
              <small>$</small>
              {spentInfraUsd}
            </b>
          </li>
          <li>
            node is currently in <b>{diffUsd > 0 ? "profit" : "deficit"}</b> of{" "}
            <b>${Number(Math.abs(diffUsd)).toFixed(0)}</b>
          </li>
        </ul>
      )) ||
        (isError && (
          <p>Error while loading blockchain data... Please refresh the page.</p>
        )) || (
          <ul>
            <li>spent gas = loading...</li>
            <li>commission = loading...</li>
            <li>infra cost = loading...</li>
            <li>conclusion = loading...</li>
          </ul>
        )}
      <p />
    </div>
  );
};
